import * as React from 'react'
import styled from 'styled-components'
import DesktopBanner from '../../common/assets/images/comunicado-desktop.png'
import MobileBanner from '../../common/assets/images/comunicado-mobile.png'
import { mobile } from '../../common/assets/utils/_breakpoints'
import * as config from '../../config/config'
import WhatsAppUtils from '../../utils/whatsApp.utils'

export default class BannerWaning extends React.Component {

  onBannerClick = () => {
    const url = WhatsAppUtils.buildUrlWithText(
      config.UME_PHONES.retailerSAC.whatsapp,
      "Atualizar+cadastro"
    )

    window.open(url, '_blank')
  }

  public render() {
    return (
      <SectionContainer>
        <BannerImgContainer>
          <MobileImage media={`(max-width: ${mobile})`} onClick={() => this.onBannerClick()} srcSet={MobileBanner} />
          <DesktopImage onClick={() => this.onBannerClick()} src={DesktopBanner} />
        </BannerImgContainer>
      </SectionContainer>
    )
  }
}

const SectionContainer = styled.div`
  padding: 30px 0px;
  :hover {
    cursor: pointer;
  }
`

const BannerImgContainer = styled.picture``

const MobileImage = styled.source`
  max-width: 90%;
  height: auto;
`

const DesktopImage = styled.img`
  max-width: 90%;
  height: auto;
`
